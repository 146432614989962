import './Home.scss';
import ContactBox from '../ContactBox/ContactBox';

export default function Home() {
  return (
    <div className="home__main">
      <div className="home__main--item">
        <div className="home__main--info-text">
          "Gemeinsames Yoga für Klarheit und Beständigkeit."
        </div>
      </div>
      <div className="home__main--item">
        <ContactBox></ContactBox>
      </div>
    </div>
  );
}
