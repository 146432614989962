import './Impressum.scss';

export default function Impressum() {
  return (
    <div className="impressum__main">
      <div className="impressum__title">Impressum</div>
      <div>
        <strong>Seitinhaber und für den Inhalt verantwortlich:</strong>
        <div>Ingrid Redl</div>
        <div>Anningergasse 4</div>
        <div>2482 Münchendorf</div>
      </div>
      <div>
        <strong>Homepagegestaltung:</strong>
        <div>Markus Walter Sturmberger</div>
      </div>
      <div>
        <strong>Danksagung für verwendete Assets:</strong>
        <div>Background Assets implementiert von https://free-psd-templates.com/</div>
        <div>Background Mountain by <a href='https://pngtree.com/freepng/black-pencil-sketch-landscape-mountain-peak_6523800.html'>png image from pngtree.com/</a></div>
      </div>
      <div>
        <strong>Haftungsausschluss:</strong>
        <div>
          Der Autor dieser Webseiten ist bestrebt, die hier angebotenen
          Informationen nach bestem Wissen und Gewissen vollständig und richtig
          darzustellen und aktuell zu halten. Dennoch kann er keinerlei Haftung
          für Schäden übernehmen, die sich aus der Nutzung der angebotenen
          Informationen ergeben können – auch wenn diese auf die Nutzung von
          allenfalls unvollständigen bzw. fehlerhaften Informationen
          zurückzuführen sind. Verweise auf fremde Webseiten liegen außerhalb
          des Verantwortungsbereiches des Autors. Eine Haftung für die Inhalte
          von verlinkten Seiten ist ausgeschlossen, zumal der Autor keinen
          Einfluss auf Inhalte wie Gestaltung von gelinkten Seiten hat. Für
          Inhalte von Seiten, auf welche von Seiten dieser Homepage verwiesen
          wird, haftet somit allein der Anbieter dieser fremden Webseiten –
          niemals jedoch derjenige, der durch einen Link auf fremde
          Publikationen und Inhalte verweist. Sollten gelinkte Seiten
          (insbesondere durch Veränderung der Inhalte nach dem Setzen des Links)
          illegale, fehlerhafte, unvollständige, beleidigende oder sittenwidrige
          Informationen beinhalten und wird der Autor dieser Seite auf derartige
          Inhalte von gelinkten Seiten aufmerksam (gemacht), so wird er einen
          Link auf derartige Seiten unverzüglich unterbinden.
        </div>
        <div>
          <div>Informationsgehalt:</div>
          <div>
            Die Informationen dieser Webseiten können ohne vorherige Ankündigung
            geändert, entfernt oder ergänzt werden. Der Autor kann daher keine
            Garantie für die Korrektheit, Vollständigkeit oder Qualität und
            letzte Aktualität der bereitgestellten Informationen geben.
          </div>
        </div>
        <div>
          <div>Rechtswirksamkeit:</div>
          <div>
            Durch Nutzung dieser Webseiten unterliegt der Nutzer den
            gegenständlichen Benützungsbedingungen.
          </div>
        </div>
        <div>
          <div>Urheberrecht:</div>
          <div>
            Jede den Bestimmungen des Urheberrechtsgesetzes widersprechende
            Verwendung jeglicher Inhalte dieser Webseiten – insbesondere die
            weitergehende Nutzung wie beispielsweise die Veröffentlichung,
            Vervielfältigung und jede Form von gewerblicher Nutzung sowie die
            Weitergabe an Dritte – auch in Teilen oder in überarbeiteter Form –
            ohne ausdrückliche Zustimmung des Autors ist untersagt. Davon
            ausgenommen sind ausdrücklich zum Download gekennzeichnete Dateien.
          </div>
        </div>
        <div>
          <div>Alle Rechte vorbehalten.</div>
          <div>© 2013 Ingrid Redl</div>
        </div>
      </div>
      <div>
        <strong>Allgemeine Geschäftsbedingungen (AGB)</strong>
        <div>
          Frau Ingrid Redl erbringt ihre Dienstleistungen ausschließlich zu den
          nachstehenden allgemeinen Geschäftsbedingungen.
        </div>
        <div>
          § 1 Vertragsgegenstand <br /> a) auf Grundlage dieser Bedingungen und
          der jeweils aktualisierten Programmübersicht bietet sie die
          Organisation und Durchführung von Yogaeinheiten an.
          <br /> b) Inhalt, Dauer und Kosten des Angebots ergeben sich aus der
          einschlägigen jeweils gültigen Angebotsbeschreibung.
        </div>
        <div>
          § 2 Anmeldung, Zustandekommen des Vertrages <br />
          Die Anmeldung kann persönlich, schriftlich (Brief, Fax, E-Mail),
          telefonisch oder per email erfolgen und wird von Frau Redl bestätigt.
          Der Vertrag kommt mit der Anmeldung und der Annahme durch Frau Redl
          zustande. Die Anmeldung verpflichtet zur Zahlung der jeweiligen
          Gebühr. Anmeldungen werden in der Reihenfolge ihres Eingangs
          berücksichtigt. Frau Redl behält sich das Recht vor, Anmeldungen
          infolge einer Überbelegung abzulehnen.
        </div>
        <div>
          § 3 Zahlungsbedingungen Wenn nicht anders vereinbart, ist der volle
          Beitrag vor Beginn der Nutzung des Angebots fällig, spätestens jedoch
          am 1. Tag der Nutzung.
        </div>
        <div>
          § 4 Nutzungsbedingungen (Übertragung, Stornierung etc.) <br />
          Sämtliche Angebote sind personenbezogen und nicht übertragbar. Die
          entsprechenden Ausweise sind vor Beginn der Einheit vorzuweisen.
          Bezahlte, aber nicht oder nur teilweise genutzte Einheiten verfallen
          ausnahmslos nach Ablauf eines Jahres. Ein Anspruch auf Rückvergütung
          ist ausgeschlossen.
        </div>
        <div>
          § 5 Absage/Ausfall von Einheiten, Änderung des Angebots <br />
          Bei nicht ausreichenden Anmeldungen (mind. 4 je Kurseinheit) oder aus
          anderen wichtigen Gründen (z.B. Krankheit,Weiterbildung,Urlaub) behält
          sich Frau Redl das Recht vor, Einheiten abzusagen. Die
          KursteilnehmerInnen werden hiervon umgehend informiert. Jede/r
          Kursteilnehmer/in ist bei Nichterscheinen verpflichtet seine Teilnahme
          bis spät. 12 Uhr des Kurstages abzusagen, ansonsten gilt sie/er als
          anwesend. Bei großem Interesse an einzelnen Einheiten/Kursen ist Frau
          Redl berechtigt eine Voranmeldung zu verlangen, um einen ungestörten
          organisatorischen Ablauf zu gewährleisten. Änderungen im Stundenplan
          oder beim Lehrpersonal können jederzeit erfolgen. Es besteht in diesen
          Fällen kein Anspruch auf Rückvergütung. Sonstige Programmänderungen
          bleiben vorbehalten.
        </div>
        <div>
          § 6 Ausschluss/Gesundheitszustand von TeilnehmerInnen <br />
          Frau Redl behält sich das Recht vor, einzelnen Personen die Teilnahme
          am Angebot zu untersagen, sofern diese den Ablauf nachhaltig
          beeinträchtigen. Weiters verpflichten sich die TeilnehmerInnen das
          Angebot von Frau Redl nur zu nutzen, wenn sie nicht unter ansteckenden
          Krankheiten leiden und der Nutzung keine medizinischen Bedenken
          entgegenstehen.
        </div>
        <div>
          § 7 Haftung <br />
          Die Teilnahme erfolgt auf eigene Gefahr. Schadenersatzansprüche
          jeglicher Art sind ausgeschlossen. Frau Redl übernimmt keine Haftung
          für abhanden gekommene Gegenstände, Gesundheitsschäden sowie für
          Unfälle jeglicher Art während und nach der Veranstaltung gegenüber
          TeilnehmerInnen und Dritten.
        </div>
        <div>
          § 8 Allgemeines <br />
          Sollte eine Bedingung oder ein Vertragsteil unwirksam sein, bleiben
          die übrigen Bestimmungen oder Vertragsteile in Kraft. Mündliche
          Nebenabreden sind nicht getroffen. Irrtum und Änderungen vorbehalten.
          Es gilt österreichisches Recht. Gerichtsstand ist Mödling.
        </div>
      </div>
    </div>
  );
}
