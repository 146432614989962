import './DataInfo.scss';

export default function DataInfo() {
  return (
    <div className="dataInfo__main">
      <div className="dataInfo__title">Datenschutz</div>
      <div>
          <div>
            Soweit auf diesen Seiten personenbezogene Daten (beispielsweise
            Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies
            soweit möglich stets auf freiwilliger Basis. Die Nutzung der
            Angebote und Dienste ist, soweit möglich, stets ohne Angabe
            personenbezogener Daten möglich. Der Nutzung von im Rahmen der
            Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur
            Übersendung von nicht ausdrücklich angeforderter Werbung und
            Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die
            Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte
            im Falle der unverlangten Zusendung von Werbeinformationen, etwa
            durch Spam-Mails, vor.
          </div>
        </div>
        <div>
          Diese Website benutzt Google Analytics, einen Webanalysedienst der
          Google Inc. („Google“) Google Analytics verwendet sog. „Cookies“,
          Textdateien, die auf Ihrem Computer gespeichert werden und die eine
          Analyse der Benutzung der Website durch Sie ermöglicht. Die durch den
          Cookie erzeugten Informationen über Ihre Benutzung diese Website
          (einschließlich Ihrer IP-Adresse) wird an einen Server von Google in
          den USA übertragen und dort gespeichert. Google wird diese
          Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um
          Reports über die Websiteaktivitäten für die Websitebetreiber
          zusammenzustellen und um weitere mit der Websitenutzung und der
          Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird
          Google diese Informationen gegebenenfalls an Dritte übertragen, sofern
          dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im
          Auftrag von Google verarbeiten. Google wird in keinem Fall Ihre
          IP-Adresse mit anderen Daten der Google in Verbindung bringen. Sie
          können die Installation der Cookies durch eine entsprechende
          Einstellung Ihrer Browser Software verhindern; wir weisen Sie jedoch
          darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
          Funktionen dieser Website voll umfänglich nutzen können. Durch die
          Nutzung dieser Website erklären Sie sich mit der Bearbeitung der über
          Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und
          Weise und zu dem zuvor benannten Zweck einverstanden.
        </div>
        <div><strong>Datenerfassung - Kontaktformular</strong></div>
        <div>
        Der intiale Transfer Ihrer Angaben im Kontaktformular erfolgt durch den externen Dienstleister EmailJS. 
        Dieser Dienst ist DSGVO konform, nachlesbar unter <a target="/blank" href ="https://www.emailjs.com/legal/privacy-policy/">diesem Link</a>.
        
        Die von Ihnen im Kontaktformular eingegebenen Daten werden von uns nicht gespeichert. 
        Zwingende gesetzliche Bestimmungen bleiben unberührt.
        </div>
    </div>
  );
}
