import { useForm, SubmitHandler } from 'react-hook-form';
import './ContactBox.scss';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PUBLIC_KEY, SERVICE_KEY, TEMPLATE_KEY, PHONE_NUMBER } from '../../app/constants';

type ContactInfo = {
  name: string;
  email: string;
  phoneNumber: number | undefined;
  message: string;
};

export default function ContactBox() {
  const initialState: ContactInfo = {
    name: '',
    email: '',
    phoneNumber: undefined,
    message: '',
  };

  const showToastSuccess = (name: string) => {
    toast.success(`Hallo ${name}, deine Anfrage wurde erfolgreich versendet!\nIch melde mich bald bei dir!`, {style: {background: '#6b9b93'}});
  };

  const showToastError = (name: string) => {
    toast.error(`Hallo ${name}, bei deiner Anfrage ging leider etwas schief!\nBitte melde dich TELEFONISCH!`);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ContactInfo>();

  const onSubmit: SubmitHandler<ContactInfo> = (data) => {
    emailjs.init(PUBLIC_KEY);
    emailjs.send(SERVICE_KEY, TEMPLATE_KEY, {
      user_email: data.email,
      user_name: data.name,
      message: data.message,
      user_phone: data.phoneNumber || ''
    }).then(() => {
      showToastSuccess(data.name);
      reset();
    }, 
    (error) => {
      console.error(error);
      showToastError(data.name);
      reset();
    })
  };

  return (
    <div className="form__container">
      <ToastContainer theme='colored'/>
      <div className="form__title">Kursinformation auf Anfrage</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>
          Name
          <input
            type="text"
            id="name"
            defaultValue={initialState.name}
            {...register('name', {
              required: 'true',
            })}
          ></input>
          <span className="form__error">{errors.name && <span>Bitte einen Namen eingeben!</span>}</span>
        </label>
        <label>
          E-Mail
          <input
            type="text"
            id="email"
            defaultValue={initialState.email}
            {...register('email', {
              required: true,
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Bitte eine gültige Email angeben!"
              }
            })}
          ></input>
           <span className="form__error">{errors.email && <span>Bitte eine gültige Email eingeben!</span>}</span>
        </label>
        <label>
          Telefonnummer
          <input
            type="tel"
            id="phoneNumber"
            placeholder="z.B. +43 123 1234567"
            inputMode="numeric"
            defaultValue={initialState.phoneNumber}
            {...register('phoneNumber')}
          ></input>
        </label>
        <label>
          Nachricht
          <textarea
            id="message"
            defaultValue={initialState.message}
            {...register('message')}
          ></textarea>
        </label>
        <input id="submit" type="submit" value="Anfrage absenden" />
      </form>
      <div className="form__additional-info">
        <span>Du möchtest direkt mit mir sprechen?</span>
        <span>
          Melde dich unter <span className="mod-green">{PHONE_NUMBER}</span>
        </span>
      </div>
    </div>
  );
}
