import { BrowserRouter } from 'react-router-dom';
import Home from '../components/Home/Home';
import { Route, Routes } from 'react-router-dom';
import Impressum from '../components/Impressum/Impressum';
import Layout from '../components/Layout/Layout';
import DataInfo from '../components/DataInfo/DataInfo';

function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="*" element={<Home />} />
        <Route path="impressum" element={<Impressum />} />
        <Route path="dataInfo" element={<DataInfo />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
