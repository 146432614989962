import './Layout.scss';
import title from '../Images/einfach_yoga_title.svg';
import email from '../Images/email_icon.svg';
import { Outlet, Link } from 'react-router-dom';

export default function Layout() {
  const scrollToTop = () => window.scrollTo({top: 0});
  return (
    <div className="layout__container">
      <header>
        <img src={title} className="layout__title-image" alt="titleImage" />
      </header>
      <div className='layout__body'>
        <Outlet />
      </div>
      <footer>
        <span className='layout__links'>
          <span>
          <Link className="layout__link" to="/home" onClick={scrollToTop}>
            ©Einfach Yoga.
          </Link>
          <Link className="layout__link" to="/impressum" onClick={scrollToTop}>
            Impressum
          </Link>
          <Link className="layout__link" to="/dataInfo" onClick={scrollToTop}>
            Datenschutz
          </Link>
          </span>
          <a href= "mailto:einfach.yoga.at@gmail.com"><img src={email} className="layout__email" alt="emailIcon" /></a>
        </span>
      </footer>
    </div>
  );
}
